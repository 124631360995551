import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { AppContext } from '_context'
import { LysTemplate } from '_components/templates/'
import variables from '_config/css-variables'

const LysCaseStudy = ({ data }) => {
  const content = data.contentfulLysCaseStudy
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)

  useEffect(() => {
    setNavbarLight(true)
  }, [setNavbarColor, setNavbarLight])

  return (
    <LysTemplate
      navbarColorOverride={variables['lys-blue-light']}
      pageTitle={content.pageTitle}
      pageDescription={content.pageDescription}
      pageBackgroundImage={content.pageThumbnail.file.url}
      pageBackgroundImageAlt={content.pageThumbnail.description}
      logoLys={content.logoLys.fluid}
      logoLysAlt={content.logoLys.description}
      firstDescription={content.firstDescription}
      secondDescription={content.secondDescription}
      firstWebPhone={content.firstWebPhone.fluid}
      firstWebPhoneAlt={content.firstWebPhone.description}
      challengeIllustration={content.challengeIllustration.fluid}
      challengeIllustrationAlt={content.challengeIllustration.description}
      challengeTitle={content.challengeTitle}
      challengeFirstParagraph={content.challengeFirstParagraph.internal.content}
      challengeSecondParagraph={content.challengeSecondParagraph.internal.content}
      challengeThirdParagraph={content.challengeThirdParagraph}
      adminTitle={content.adminTitle}
      adminDescription={content.adminDescription.internal.content}
      adminIllustration={content.adminIllustration.fluid}
      adminIllustrationAlt={content.adminIllustration.description}
      panelTitle={content.panelTitle}
      panelDescription={content.panelDescription.internal.content}
      panelIllustration={content.panelIllustration.file.url}
      panelIllustrationAlt={content.panelIllustration.description}
      studentIllustration={content.studentIllustration.file.url}
      studentIllustrationAlt={content.studentIllustration.description}
      studentIllustrationMobile={content.studentIllustrationMobile.file.url}
      studentIllustrationMobileAlt={content.studentIllustrationMobile.description}
      studentTitle={content.studentTitle}
      studentDescription={content.studentDescription.internal.content}
      brandsTitle={content.brandsTitle}
      brandsDescription={content.brandsDescription.internal.content}
      brandsIllustration={content.brandsIllustration.file.url}
      brandsIllustrationAlt={content.brandsIllustration.description}
      proudCases={content.proudCases}
      resultsTitle={content.resultTitle}
      resultsFirstLogo={content.resultsLogo.file.url}
      resultsFirstLogoAlt={content.resultsLogo.description}
      resultsSecondLogo={content.resultsSecondLogo.file.url}
      resultsSecondLogoAlt={content.resultsSecondLogo.description}
      resultsDescriptionFirstlogo={content.resultsDescriptionFirstLogo}
      resultsDescriptionSecondLogo={content.resultsDescriptionSecondLogo}
      resultsTextFirst={content.resultsTextFirst}
      resultsTextSecond={content.resultsTextSecond}
      resultsTextThird={content.resultsTextThird}
      resultsTextFourth={content.resultsTextFourth}
      resultsTextFifth={content.resultsTextFifth}
      resultsTextSixth={content.resultsTextSixth}
      testimonials={content.testimonials}
    />
  )
}
export const query = graphql`
  query contentfulLysCaseStudy($locale: String) {
    contentfulLysCaseStudy(fields: { localeKey: { eq: $locale } }) {
      logoLys {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      pageTitle
      pageDescription
      pageThumbnail {
        description
        file {
          url
        }
      }
      firstWebPhone {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      firstDescription
      secondDescription
      challengeIllustration {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      challengeTitle
      challengeFirstParagraph {
        internal {
          content
        }
      }
      challengeSecondParagraph {
        internal {
          content
        }
      }
      challengeThirdParagraph
      adminTitle
      adminDescription {
        internal {
          content
        }
      }
      adminIllustration {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      panelTitle
      panelDescription {
        internal {
          content
        }
      }
      panelIllustration {
        description
        file {
          url
        }
      }
      studentIllustration {
        description
        file {
          url
        }
      }
      studentIllustrationMobile {
        description
        file {
          url
        }
      }
      studentTitle
      studentDescription {
        internal {
          content
        }
      }
      brandsTitle
      brandsDescription {
        internal {
          content
        }
      }
      brandsIllustration {
        description
        file {
          url
        }
      }

      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
      resultTitle
      resultsLogo {
        description
        file {
          url
        }
      }
      resultsSecondLogo {
        description
        file {
          url
        }
      }
      resultsTextFirst
      resultsTextSecond
      resultsTextThird
      resultsTextFourth
      resultsTextFifth
      resultsTextSixth
      testimonials {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
    }
  }
`
LysCaseStudy.propTypes = {
  data: PropTypes.shape().isRequired,
}

export default LysCaseStudy
